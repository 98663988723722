<script setup lang="ts">
import { normalizeTextBlockV2, type TextBlockV2 } from '~/types/storyblok/text-block-v2'
import { getEdgeClasses } from '~/lib/getEdgeClasses'
import type { trackBlockClickEmit } from '~/types/track-block-click'

interface Props {
  blok: TextBlockV2
}

const props = defineProps<Props>()
defineEmits<trackBlockClickEmit>()
const blok = toRef(props, 'blok')

const textBlock = computed(() => normalizeTextBlockV2(blok.value))
const bgColor = textBlock.value.backgroundColor
const textColor = textBlock.value.fontAndCtaColor

const normalizedText = useRichtext(computed(() => textBlock.value.text))
const longTitle = computed(() => textBlock.value.longTitle?.split('<br>'))

const { bgInvertedColor } = useBackgroundInvertedColor(bgColor)
</script>

<template>
  <section
    v-editable="blok"
    class="text-block" :class="[
      {
        'only-title':
          !textBlock.ctaLabel
          && !textBlock.ctaLink
          && !textBlock.label
          && !textBlock.link
          && !textBlock.text?.content?.find(el => el.content)
          && !textBlock.isCentered,
      },
      { centered: textBlock.isCentered },
      {
        'without-text':
          !textBlock.text?.content?.find(el => el.content)
          && (textBlock.isOnlyTitleLeft || textBlock.isContentLeft),
      },
      { 'title-left': textBlock.isOnlyTitleLeft },
      { high: textBlock.isHigh },
      getEdgeClasses(textBlock.edgeStyle),
    ]"
  >
    <div v-if="!textBlock.text" class="text-block__placeholder" />
    <div class="text-block__container">
      <div class="text-block__left-section">
        <h3 v-if="textBlock.shortTitle" class="text-block__title text-block__title--short">
          {{ textBlock.shortTitle }}
        </h3>
        <h2 v-if="textBlock.longTitle" class="text-block__title text-block__title--long">
          <span v-for="(titlePart, index) in longTitle" :key="index" class="splitted-text">
            {{ titlePart }}
          </span>
        </h2>
        <div
          v-if="textBlock.text && textBlock.isContentLeft"
          class="text-block__text"
          v-html="normalizedText"
        />
        <ButtonIcon
          v-if="textBlock.ctaLink && textBlock.ctaLabel && textBlock.isContentLeft"
          class="btn"
          :text="$t(textBlock.ctaLabel)"
          left-icon="bullet"
          right-icon="forward"
          :blinking="true"
          :background-color="textBlock.fontAndCtaColor"
          :text-color="textBlock.backgroundColor"
          data-test="cta-internal-link-btn"
          tag="nuxt-link"
          :target="textBlock.ctaLink.startsWith('http') ? '_blank' : '_self'"
          :to="$url(textBlock.ctaLink)"
          @click="() => $emit('trackBlockClick', $url(textBlock.ctaLink), 'button')"
        />
        <NuxtLink
          v-if="textBlock.link && textBlock.label && textBlock.isContentLeft"
          class="text-block__link"
          :target="$url(textBlock.link).startsWith('http') ? '_blank' : '_self'"
          :to="$url(textBlock.link)"
          @click="() => $emit('trackBlockClick', $url(textBlock.link), 'link')"
        >
          {{ textBlock.label }}
        </NuxtLink>
      </div>
      <div class="text-block__right-section">
        <div
          v-if="textBlock.isOnlyTitleLeft || textBlock.isCentered"
          class="text-block__text"
          v-html="normalizedText"
        />
        <ButtonIcon
          v-if="
            textBlock.ctaLink
              && textBlock.ctaLabel
              && (textBlock.isOnlyTitleLeft || textBlock.isCentered)
          "
          class="btn"
          :text="$t(textBlock.ctaLabel)"
          left-icon="bullet"
          right-icon="forward"
          :blinking="true"
          :background-color="textBlock.fontAndCtaColor"
          :text-color="textBlock.backgroundColor"
          data-test="cta-internal-link-btn"
          tag="nuxt-link"
          :target="textBlock.ctaLink.startsWith('http') ? '_blank' : '_self'"
          :to="textBlock.email ? `mailto:${textBlock.email}` : $url(textBlock.ctaLink)"
          @click="() => $emit('trackBlockClick', $url(textBlock.ctaLink), 'button')"
        />
        <NuxtLink
          v-if="
            textBlock.link && textBlock.label && (textBlock.isOnlyTitleLeft || textBlock.isCentered)
          "
          class="text-block__link"
          :target="$url(textBlock.link).startsWith('http') ? '_blank' : '_self'"
          :to="$url(textBlock.link)"
          @click="() => $emit('trackBlockClick', $url(textBlock.link), 'link')"
        >
          {{ textBlock.label }}
        </NuxtLink>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import 'assets/scss/typography/headings';
@import 'assets/scss/typography/body';
@import 'assets/scss/rules/edges';
@import 'assets/scss/rules/breakpoints';

.text-block {
  background-color: v-bind(bgColor);
  position: relative;
  color: v-bind(textColor);
  padding: 3.2rem 1.6rem 4rem;

  @media (min-width: $tablet) {
    padding: 3.2rem 2.4rem 4rem;
  }

  &__placeholder {
    width: 50%;
  }

  &__container {
    display: flex;
    flex-direction: column;

    @media (min-width: $tablet) {
      flex-direction: row;
    }
  }

  &__left-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: $tablet) {
      width: 50%;
    }
  }

  &__right-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__title {
    margin-bottom: 1.6rem;
    &--short {
      @include h4;
      color: v-bind(textColor);

      @media (min-width: $tablet) {
        @include h3;
        color: v-bind(textColor);
        max-width: 47rem;
      }
    }
    &--long {
      @include h6;
      color: v-bind(textColor);

      @media (min-width: $tablet) {
        @include h5;
        color: v-bind(textColor);
        max-width: 67rem;
      }
    }
  }

  &__text {
    @include body1;
    color: v-bind(textColor);
    max-width: 49.2rem;
  }

  &__link {
    text-decoration: underline;
    display: block;
    margin-top: 2.4rem;
    color: v-bind(textColor);
      &:focus-visible {
      outline: 2px solid v-bind(bgInvertedColor);
      }
  }
}

.without-text {
  .text-block {
    @media (min-width: $tablet) {
      &__container {
        flex-direction: column;
        margin-left: 50%;
      }
      &__left-section {
        width: 100%;
      }
    }
  }
  &.only-title {
    .text-block {
      &__container {
        margin-left: 0;
      }
    }
  }
}
.centered {
  .text-block {
    &__container {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
    &__left-section {
      width: 100%;
      align-items: center;
    }

    &__right-section {
      width: 100%;
      align-items: center;
    }
  }
  &.only-title {
    .text-block {
      &__left-section {
        align-items: flex-start;
      }
    }
  }
}

.title-left {
  padding: 2.4rem 1.6rem 5.6rem;
  @media (min-width: $tablet) {
    padding: 2.4rem 2.4rem 5.6rem;
  }
  .text-block {
    &__right-section {
      @media (min-width: $tablet) {
        width: 50%;
      }
    }
    &__text {
      max-width: unset;
    }
    &__title {
      margin-bottom: 0;
    }
  }
}

.splitted-text {
  display:block;
}

.high {
  padding-top: 12rem;
}

.btn {
  margin-top: 4rem;
  &:focus-visible {
      outline: 2px solid v-bind(bgInvertedColor);
  }
}

.teeth {
  @include teeth;
  margin-top: -1.2rem;
}

.scallop {
  @include scallop;
  margin-top: -1.2rem;
}

.wave {
  @include wave;
  margin-top: -1.2rem;
}
</style>
